<template>
  <div class="broker-footer">
    <div class="container">
      <div class="row row-gap-5 mb-5">
        <div class="col-md-6 col-lg-3">
          <RouterLink to="/broker">
            <img
              src="https://holaprimetest.s3.us-east-2.amazonaws.com/images/1731740280414.webp"
              alt="footer_logo"
              class="footer_logo"
              loading="lazy"
              width="81px"
            />
          </RouterLink>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="links-group">
            <div class="links-title text-white">Menu</div>
            <ul>
              <li>
                <RouterLink
                  class="text-white sub-text text-decoration-none"
                  to="/broker"
                >
                  Home
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  class="text-white sub-text text-decoration-none"
                  to="/broker/about"
                >
                  About Us
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  class="text-white sub-text text-decoration-none"
                  to="/broker/trading-platforms"
                >
                  Trading Platforms
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  class="text-white sub-text text-decoration-none"
                  to="/broker/contact-us"
                >
                  Contact Us
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="links-group">
            <div class="links-title text-white">Trading Products</div>
            <ul>
              <li>
                <RouterLink
                  class="text-white sub-text text-decoration-none"
                  to="/broker/forex"
                >
                  Forex
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  class="text-white sub-text text-decoration-none"
                  to="/broker/indices"
                >
                  Indices
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  class="text-white sub-text text-decoration-none"
                  to="/broker/commodities"
                >
                  Commodities
                </RouterLink>
              </li>
              <li>
                <RouterLink
                  class="text-white sub-text text-decoration-none"
                  to="/broker/cryptocurrencies"
                >
                  Cryptocurrencies
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="links-group">
            <div class="links-title text-white">Address</div>
            <ul>
              <li>
                <p class="text-white sub-text fw-bold mb-1">
                  Hola Markets Limited
                </p>
                <p class="text-white sub-text fw-bold mb-1">
                  Registered Number: 15743
                </p>
                <p class="text-white sub-text mb-0">
                  Hamchako, <br />
                  Mustamudu, The Autonomous <br />
                  Island of Anjouan, Union of <br />
                  Comoros
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="">
        <p class="text-description mb-0">
          Disclaimer: Trading leveraged instruments and CFDs is risky and
          carries inherent risk of losing money. Please understand these risks
          carefully and do not invest any money that you cannot afford to lose.
        </p>

        <p class="text-description mb-0">
          The information on the website is not directed at any jurisdiction and
          is not intended for any use that would be contrary to local law or
          regulation.
        </p>
        <p class="text-description mb-0">
          Hola Prime does not provide services to the residents of certain
          countries including – United States of America, Afghanistan, Belarus,
          Burundi, Cuba, Congo, Sudan, Sri Lanka, North Korea (Democratic
          People’s Republic of Korea) and Yemen.
        </p>
        <p class="text-description mb-0">
          This is the only website for Hola Prime. We are not using any third
          party websites or links. Any link, outside of this website that claims
          to be ours, could be fraudulent and users are advised to not use it.
        </p>
      </div>
    </div>
  </div>
</template>
