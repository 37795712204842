import {
  createRouter,
  createWebHistory
} from "vue-router";

const routes = [{
    path: "/",
    name: "home",
    component: () => import("@/views/lp/index.vue"),
  },
  {
    path: "/careers",
    name: "Careers",
    component: () => import("@/views/career.vue"),
  },
  {
    path: "/blackfriday",
    name: "blackfriday home",
    component: () => import("@/views/blackFriday/index.vue"),
  },
  {
    path: "/blackfriday2",
    name: "blackfriday home 2",
    component: () => import("@/views/blackFriday/blackFriday2.vue"),
  },
  {
    path: "/about-us",
    name: "About US",
    component: () => import("@/views/aboutUs.vue"),
  },
  {
    path: "/affiliate-program",
    name: "Affiliate Program",
    component: () => import("@/views/affiliatePartner.vue"),
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: () => import("@/views/contactUs.vue"),
  },
  {
    path: "/faq",
    children: [{
        path: "",
        name: "FAQ",
        component: () => import("@/views/Faq/index.vue"),
      },
      {
        path: "about-hola",
        name: "About Hola Prime",
        component: () => import("@/views/Faq/aboutHolaFaq.vue"),
      },
      {
        path: "general",
        name: "General FAQ",
        component: () => import("@/views/Faq/generalFaq.vue"),
      },
      {
        path: "begin",
        name: "How to Begin?",
        component: () => import("@/views/Faq/beginFaq.vue"),
      },
      {
        path: "funding-plans",
        name: "Funding Plans",
        component: () => import("@/views/Faq/fundingPlansFaq.vue"),
      },
      {
        path: "scaling",
        name: "Scaling and Alpha Prime Membership",
        component: () => import("@/views/Faq/scalingFaq.vue"),
      },
      {
        path: "prime-lessons",
        name: "Prime Lessons",
        component: () => import("@/views/Faq/primeLessonsFaq.vue"),
      },
      {
        path: "payouts",
        name: "Payouts",
        component: () => import("@/views/Faq/payoutsFaq.vue"),
      },
      {
        path: "affilate",
        name: "Affilate FAQ",
        component: () => import("@/views/Faq/affilateFaq.vue"),
      },
      {
        path: "trading-condition",
        name: "Trading Conditions",
        component: () => import("@/views/Faq/tradingConditionFaq.vue"),
      },
    ],
  },
  {
    path: "/teams",
    name: "Teams",
    component: () => import("@/views/teams.vue"),
  },
  {
    path: "/trading-rules",
    name: "trading-rules",
    component: () => import("@/views/tradingRules.vue"),
  },
  {
    path: "/prohibited-trading-practices",
    name: "Trading Rules Content",
    component: () => import("@/views/tradingRulesContent.vue"),
  },
  {
    path: "/trading-platforms",
    name: "Trading Platforms",
    component: () => import("@/views/tradingPlatforms.vue"),
  },
  {
    path: "/trading-tools",
    name: "Trading Tools",
    component: () => import("@/views/tradingTools.vue"),
  },

  // education page dropdown
  {
    path: "/blogs",
    children: [{
        path: "",
        name: "Blogs",
        component: () => import("@/views/Blogs/index.vue"),
      },
      {
        path: "category/:category",
        name: "Blogs Category",
        component: () => import("@/views/Blogs/blogCategory.vue"),
      },
    ],
  },
  {
    path: "/blog/:slug",
    name: "Blogs Details",
    component: () => import("@/views/Blogs/blogDetails.vue"),
  },
  // {
  //   path: "/alpha-prime",
  //   name: "Alpha Prime",
  //   component: () => import("@/views/alphaPrime.vue"),
  // },
  {
    path: "/transparency-report",
    name: "Transparency Report",
    component: () => import("@/views/transparencyReport.vue"),
  },
  {
    path: "/scaling",
    name: "Scaling",
    component: () => import("@/views/scaling.vue"),
  },

  {
    path: "/pro-challenge",
    name: "Pro challenge",
    component: () => import("@/views/proChallenge.vue"),
  },

  {
    path: "/prime-challenge",
    name: "Prime challenge",
    component: () => import("@/views/primeChallenge.vue"),
  },

  {
    path: "/challenge-comparison",
    name: "Challenge Comparison",
    component: () => import("@/views/challengeComparison.vue"),
  },

  {
    path: "/visa-card",
    name: "Visa Card",
    component: () => import("@/views/visaCard.vue"),
  },


  // education page dropdown
  {
    path: "/trading-coach",
    name: "Trading Coach",
    component: () => import("@/views/tradingCoach.vue"),
  },

  {
    path: "/prime-academy",
    children: [{
        path: "",
        name: "Prime Academy",
        component: () => import("@/views/PrimeAcademy/index.vue"),
      },
      {
        path: ":slug",
        name: "Prime Academy Lesson",
        component: () => import("@/views/PrimeAcademy/lesson.vue"),
      },
    ],
  },
  // {
  //   path: "/quiz",
  //   name: "Quiz",
  //   component: () => import("@/views/quiz.vue"),
  // },
  {
    path: "/leaderboard",
    name: "Prime Leaderboard",
    component: () => import("@/views/primeLeaderboard.vue"),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("@/views/privacyPolicy.vue"),
  },
  {
    path: "/terms-conditions",
    name: "Terms & Conditions",
    component: () => import("@/views/termsCondition.vue"),
  },
  {
    path: "/risk-disclosure",
    name: "Risk Disclosure",
    component: () => import("@/views/riskDisclosure.vue"),
  },
  {
    path: "/anti-money-laundering-policy",
    name: "Anti-money laundering policy",
    component: () => import("@/views/antiMoneyLaundering.vue"),
  },

  {
    path: "/1-hour-payouts",
    name: "Hours Payout",
    component: () => import("@/views/hoursPayout.vue"),
  },

  {
    path: "/lp",
    name: "Landing Page",
    component: () => import("@/views/home.vue"),
  },

  {
    path: "/competition",
    name: "Competition",
    component: () => import("@/views/competition.vue"),
  },

  {
    path: "/broker",
    children: [{
        path: "",
        name: "Broker",
        component: () => import("@/views/broker/index.vue"),
      },
      {
        path: "about",
        name: "Broker About",
        component: () => import("@/views/broker/aboutus.vue"),
      },
      {
        path: "trading-platforms",
        name: "Broker MT5",
        component: () => import("@/views/broker/metaTrader5.vue"),
      },
      {
        path: "forex",
        name: "Broker Forex",
        component: () => import("@/views/broker/forex.vue"),
      },
      {
        path: "commodities",
        name: "Broker Commodities",
        component: () => import("@/views/broker/commodities.vue"),
      },
      {
        path: "indices",
        name: "Broker Indices",
        component: () => import("@/views/broker/indices.vue"),
      },
      {
        path: "cryptocurrencies",
        name: "Broker Cryptocurriencies",
        component: () => import("@/views/broker/cryptocurrencies.vue"),
      },
      {
        path: "contact-us",
        name: "Broker Contact Us",
        component: () => import("@/views/broker/contact.vue"),
      }
    ],
  },
  // PAGE NOT FOUND
  {
    name: "PageNotFound",
    path: "/:pathMatch(.*)*",
    component: () => import("../views/PageNotFound.vue"),
    meta: {
      authRequired: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // linkActiveClass: "active",
  linkExactActiveClass: "nav-link active",
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0
    };
  },
});

export default router;