<template>
  <component :is="layoutComponent">
    <router-view />
  </component>
  <LiveChatWidget
    ref="liveChat"
    license="18558708"
    :visibility="visibilityData ? 'minimized' : 'maximized'"
    @visibility-changed="onVisibilityChanged"
    @new-event="handleNewEvent"
  />
</template>

<script setup>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import BrokerLayout from "@/components/layout/BrokerLayout.vue";
import { ref, computed, watch, onMounted } from "vue";
import { LiveChatWidget, useWidgetState } from "@livechat/widget-vue";
import { eventBus } from "@/eventBus.js";
import { useRoute } from "vue-router";

const route = useRoute();
const visibilityData = ref(true);
const widgetState = useWidgetState();

const layoutComponent = computed(() => {
  const brokerRoutes = [
    "Broker",
    "Broker About",
    "Broker MT5",
    "Broker Forex",
    "Broker Commodities",
    "Broker Indices",
    "Broker Cryptocurriencies",
    "Broker Contact Us",
  ];
  return brokerRoutes.includes(route.name) ? BrokerLayout : DefaultLayout;
});

function handleNewEvent(event) {
  console.log("LiveChatWidget.onNewEvent", event);
}

function openLiveChat() {
  visibilityData.value = !visibilityData.value;
}
function onVisibilityChanged(data) {
  visibilityData.value = widgetState.value?.visibility == "maximized";
}

onMounted(() => {
  eventBus.value.openLiveChat = openLiveChat;
  visibilityData.value = true;
});

watch(route, () => {
  const existingScripts = document.querySelectorAll(
    'script[type="application/ld+json"]'
  );
  existingScripts.forEach((script) => {
    document.head.removeChild(script);
  });
});
</script>
