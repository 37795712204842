<template>
    <Header />
    <div class="page_content" :class="route.name == 'home' ? 'lp_main' : ''">
      <router-view />
    </div>
    <Footer />
  </template>

<script setup>
import Header from "@/components/view/DefaultHeader.vue";
import Footer from "@/components/view/DefaultFooter.vue";
import { useRoute } from 'vue-router';

const route = useRoute();
</script>
